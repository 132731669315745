<script>
	export let target = false;

  const imgAtts = target.querySelector('img') ? target.querySelector('img').attributes : false;
  const anchorAtts = target.querySelector('a') ? target.querySelector('a').attributes : false;
  const name = target.children[1] ? target.children[1].innerText : '';
  const isEmpty = (target.children.length === 0);

  let img = {};
  let anchor = {};

  Object.entries(imgAtts).forEach(([k, v]) => img[v.nodeName] = v.nodeValue);
  Object.entries(anchorAtts).forEach(([k, v]) => anchor[v.nodeName] = v.nodeValue);

  Array.from(target.children).forEach((node) => node.remove());

</script>


{#if !isEmpty}
  <div class="sponsor-card">
    <a href="{anchor.href}" target="{anchor.target}" rel="{anchor.rel}" class="card-inner">
    <span class="image-wrap">
      <img width="{img.width}" height="{img.height}" src="{img.src}" srcset="{img.srcset}" alt="{img.alt}" />
    </span>
      <span class="name">{name}</span>
    </a>
  </div>
{/if}
