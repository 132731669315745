<script type="ts">
  import { mobileMenuToggleState } from '../utilities/store';

  let isOpenMobileNav = false;

  function toggleState() {
    isOpenMobileNav = !isOpenMobileNav;
    $mobileMenuToggleState = isOpenMobileNav;
  }

  // add class to body and mobile nav conatiner with toggle state
  $: document.body.classList.toggle('is-open-mobile-nav', isOpenMobileNav);
  //$: document.getElementById('mobileNavContainer').classList.toggle('is-open-mobile-nav', isOpenMobileNav);

</script>

<a href="#" class="nav-toggle-btn" on:click={(e) => toggleState(e)} role="button">
  <span></span>
  <span></span>
  <span></span>
</a>

<style lang="scss">
  .nav-toggle-btn {
    @apply block w-[1.125rem] h-[1.125rem] m-0 p-0;
    span {
      @apply block w-[1.125rem] h-[2px] bg-white;
      &:nth-of-type(1),
      &:nth-of-type(2) {
        @apply mb-[4px]
      }
    }
    &:focus {
      border: none;
      outline: none;
    }
  }
</style>
