<script>
	import { breakpoint } from '../utilities/config';
	import { fly } from 'svelte/transition';
	import { mainNavItems, mobileMenuToggleState } from '../utilities/store';

	export let depth = 0;
	export let navItems = walkNavItems([...$mainNavItems.children]);

	let innerWidth = window.innerWidth;

	function walkNavItems(navArray) {
		return navArray.map((item) => {
			let submenu = (item.classList.contains('menu-item-has-children')) ? Array.from(item.children[1].children) : null;
			return {
				liClass: item.classList,
				title: item.children[0].innerText,
				href: item.children[0].href,
				submenu: (submenu !== null) ? walkNavItems(submenu) : false
			}
		});
	}

	function scrollTo(e) {
		$mobileMenuToggleState = false;
		e.preventDefault();
		if (e.target.hash !== "") {
			const offset = 50;
			const targetEl = document.querySelector(e.target.hash);
			const bodyRect = document.body.getBoundingClientRect().top;
			const elementRect = targetEl.getBoundingClientRect().top;
			const elementPos = elementRect - bodyRect;
			const offsetPos = elementPos - offset;

			window.scrollTo({ top: offsetPos, behavior: 'smooth'});
			history.pushState(null, null, e.target.hash);
		}
	}


	$:{
		if (innerWidth >= breakpoint.lg) {
			$mobileMenuToggleState = false;
		}
	}

</script>

<svelte:window bind:innerWidth />

{#if $mobileMenuToggleState}
  <div class="mobilenav-container" transition:fly="{{ y: -500, duration: 500 }}">
    <ul class="level-{depth}">
      {#each navItems as navItem}
        <li class="{navItem.liClass}">
          <a href="{navItem.href}" class="menu-item-link" on:click={(e) => scrollTo(e)}>{navItem.title}</a>
        </li>
      {/each}
    </ul>
  </div>
{/if}
