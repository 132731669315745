<script>

  export let target;
  const moreLink = target.querySelector('.wp-block-cover__inner-container a');

	const scrollTo = (e) => {
		e.preventDefault();
		if (e.target.hash !== "") {

			const offset = 60;
			const targetEl = document.querySelector(e.target.hash);
			const bodyRect = document.body.getBoundingClientRect().top;
			const elementRect = targetEl.getBoundingClientRect().top;
			const elementPos = elementRect - bodyRect;
			const offsetPos = elementPos - offset;

			window.scrollTo({ top: offsetPos, behavior: 'smooth'});
			history.pushState(null, null, e.target.hash);
		}
	}

	moreLink.addEventListener('click', scrollTo);

</script>
