<script>
  export let target = false;

  const firstContentElement = target.children[0].children[0];

  if (firstContentElement.classList.contains('topline')) {

		const div = document.createElement('div');
  	const span = document.createElement('span');

  	span.innerText = firstContentElement.innerText;
    div.classList = firstContentElement.classList;
    div.appendChild(span);
		target.insertBefore(div, target.firstChild);
		firstContentElement.remove();

  }

</script>
