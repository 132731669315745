<script type="ts">

export let mainNav: HTMLElement;
export let depth = 0;
export let navItems: any[] = walkNavItems([...mainNav.children]);

function walkNavItems(navArray: any[]) {
    return navArray.map((item) => {
        let submenu: never[] = (item.classList.contains('menu-item-has-children')) ? Array.from(item.children[1].children) : null;
        return {
            liClass: item.classList,
            title: item.children[0].innerText,
            href: item.children[0].href,
            submenu: (submenu !== null) ? walkNavItems(submenu) : false
        }
    });
}

const showSubmenu = (e: any) => {
    let submenu =  e.currentTarget.children[0].nextElementSibling;
    let submenus = Array.from(document.getElementsByClassName('submenu'));
    if (submenu !== null) {
        submenus.forEach((item) => item.classList.remove('show', 'hide'));
        submenu.classList.add('show');
    }
}

const hideSubmenu = (e: any) => {
    let submenu =  e.currentTarget.children[0].nextElementSibling;
    if (submenu !== null) {
        submenu.classList.add('hide');
        submenu.classList.remove('show');
        setTimeout(() => submenu.classList.remove('hide'), 500);
    }
}

const scrollTo = (e:any) => {
    e.preventDefault();

    if (e.target.hash !== "") {

        const offset = 80;
        const targetEl = document.querySelector(e.target.hash);
        const bodyRect = document.body.getBoundingClientRect().top;
        const elementRect = (targetEl) ? targetEl.getBoundingClientRect().top : bodyRect;
        const elementPos = elementRect - bodyRect;
        const offsetPos = elementPos - offset;

        window.scrollTo({ top: offsetPos, behavior: 'smooth'});
        history.pushState(null, null, e.target.hash);
    }
}

</script>

<nav class="desktop-navigation">
  <ul class="level-{depth}">
    {#each navItems as navItem}
      <li class={navItem.liClass} on:mouseenter={showSubmenu} on:mouseleave={hideSubmenu}>
        <a href="{navItem.href}" class="menu-item-link" on:click={(e) => scrollTo(e)} >{navItem.title}</a>
        {#if Array.isArray(navItem.submenu)}
          <div class="submenu">
            <svelte:self navItems={navItem.submenu} depth={depth + 1} />
          </div>
        {/if}
      </li>
    {/each}
  </ul>
</nav>
