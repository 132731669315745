<script>
  export let target = false;

	const atts = target.querySelector('img') ? target.querySelector('img').attributes : false;
	const name = (target.children[1]) ? target.children[1].innerText : '';
	const role = (target.children[2]) ? target.children[2].innerText : '';


	let img = {};
	Object.entries(atts).forEach(([k, v]) => img[v.nodeName] = v.nodeValue);
	Array.from(target.children).forEach((node) => node.remove());
</script>

<div class="coach-card">
  <figure>
    <div class="image-wrap">
      <img width="{img.width}" height="{img.height}" src="{img.src}" srcset="{img.srcset}" alt="{img.alt}" loading="{img.loading}"/>
    </div>
  </figure>
  <span class="name">{name}</span>
  <span class="role">{role}</span>
</div>
