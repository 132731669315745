<script>
	import { createEventDispatcher } from 'svelte';
  export let newsContent = false;
	const dispatch = createEventDispatcher();

	const closeModal = () => {
		dispatch('hideModal', {
			modalState: false
		});
  }

  let children = [...newsContent.children];
	let part1 = children.slice(0, children.length-1);
	let part2 = children.pop();
	let part2Link = part2.querySelector('a');

	const scrollTo = (e) => {
		e.preventDefault();
		closeModal();

		if (e.target.hash !== "") {
			const offset = 80;
			const targetEl = document.querySelector(e.target.hash);
			const bodyRect = document.body.getBoundingClientRect().top;
			const elementRect = targetEl.getBoundingClientRect().top;
			const elementPos = elementRect - bodyRect;
			const offsetPos = elementPos - offset;
			window.scrollTo({ top: offsetPos, behavior: 'smooth'});
			history.pushState(null, null, e.target.hash);
		}
	}
</script>

<div class="news-modal-inner">
  {#each part1 as p1}
    {@html p1.outerHTML}
  {/each}
  <p class="text-center"><a href="{part2Link.href}" on:click={e => scrollTo(e)}>{part2Link.innerText}</a></p>
  <span class="modal-close" on:click={closeModal}>schließen</span>
</div>

