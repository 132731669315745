<script>
  import { breakpoint } from '../utilities/config';
  import {fade} from "svelte/transition";
  import NavbarInner from "./NavbarInner.svelte";

  export let logoSrc, linkHref;

  let y;
  let innerWidth = window.innerWidth;
	let innerHeight = window.innerHeight;

</script>

<svelte:window bind:scrollY={y} bind:innerWidth />

{#if innerWidth < breakpoint.lg }
<div class="navbar is-fixed" class:cover={y >= innerHeight-100} class:mobile={innerWidth < breakpoint.lg}>
  <NavbarInner {logoSrc} {linkHref}/>
</div>
{:else if y >= 200 && innerWidth >= breakpoint.lg}
  <div class="navbar is-fixed" transition:fade={{duration:300}}>
    <NavbarInner {logoSrc} {linkHref}/>
  </div>
{:else}
<div class="navbar">
  <NavbarInner {logoSrc} {linkHref}/>
</div>
{/if}
