<script>
	import { onMount } from 'svelte';
	import { fade } from 'svelte/transition';
	import { mainNavItems } from '../utilities/store';
	import Navbar from "./Navbar.svelte";
	import NewsModal from "./NewsModal.svelte";

  export let target = false;
	let showNews = false;

  const mainNav = target.querySelector('ul');
  const logo = target.querySelector('img');
  const link = target.querySelector('a');
	const newsContent = document.getElementsByClassName('breaking') ? document.getElementsByClassName('breaking')[0] : false;
	const siteWrap = document.querySelector('.site');


  $mainNavItems = mainNav;
  Array.from(target.children).forEach((node) => node.remove());

	const showModal = () => {
		showNews = true;
		siteWrap.classList.add('is-modal')
	};
	const  hideModal = (e) => {
		showNews = e.detail.modalstate;
		siteWrap.classList.remove('is-modal')
	};

	function handleMessage(event) {
		alert(event.detail.text);
	}

	onMount(() => {
		setTimeout(() => {
			if (newsContent && !sessionStorage.getItem('shown-modal')) {
				showModal();
				sessionStorage.setItem('shown-modal', 'true');
			}
		}, 2500);
	});

</script>


<Navbar logoSrc="{logo.src}" linkHref="{link.href}" />

{#if showNews}
<div class="news-modal" transition:fade>
  <NewsModal newsContent="{newsContent}" on:hideModal={hideModal} />
</div>
{/if}
