<script>
	export let target = false;

	const thElements = Array.from(target.querySelectorAll('th'));
	const rows = Array.from(target.querySelectorAll('tr'));

	let innerWidth = window.innerWidth;
	let colspan = true;

	const rooms = thElements.map(item => item.innerText);

	rows.forEach(row => {
		let cells = Array.from(row.querySelectorAll('td'));
		let count = cells.length;

		cells.forEach((cell, i) => {

			//let width = 85 / (count - 1);
			let split = cell.innerHTML.split("<br>");
      let cellInner;

			if (i !== 0) {

				//if (innerWidth >= 1024) cell.style.width = width + '%';
				let part1 = '<strong>' + split[0] + '</strong>';
				let part2 = (split[1] !== undefined) ? '<br>&nbsp;&nbsp;' + split[1] + '&nbsp;&nbsp;' : '';
				let part3 = (split[2] !== undefined) ? '<br>' + split[2] : '';
				let room = (rooms[i] !== undefined) ? rooms[i] : '';

				cellInner = '<span class="w-32">' + room  + '</span><span>' + part1 + part2 + part3 + '</span>';
				cell.innerHTML = cellInner.replace(/[*]/g, "★");
			}
    });



	});

	//Array.from(target.children).forEach((node) => node.remove());

</script>
