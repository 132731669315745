<script>
	import { slide } from 'svelte/transition';
	export let target;
	let isOpen = false;

	const title = target.querySelector('.faq-title').innerText;
	const content = target.querySelector('.faq-content').innerHTML;


	/**
	 * Sanitize and encode all HTML in a user-submitted string
	 * https://portswigger.net/web-security/cross-site-scripting/preventing
	 * @param  {String} str  The user-submitted string
	 * @return {String} str  The sanitized string
	 */
	const sanitizeHTML = (str) => {
		return str.replace(/javascript:/gi, '').replace(/[^\w-_. ]/gi, function (c) {
			return `&#${c.charCodeAt(0)};`;
		});
	}

	function stripTags(input, allowed) {
		allowed = (((allowed || '') + '')
			.toLowerCase()
			.match(/<[a-z][a-z0-9]*>/g) || [])
			.join(''); // making sure the allowed arg is a string containing only tags in lowercase (<a><b><c>)
		const tags = "/<\/?([a-z][a-z0-9]*)\b[^>]*>/gi";
		return input.replace(tags, function($0, $1) {
				return allowed.indexOf('<' + $1.toLowerCase() + '>') > -1 ? $0 : '';
			});
	}

	const sanitizedContent = stripTags(content, '<b><i><u><p><ol><ul><h2><h3>')

	Array.from(target.children).forEach(node => node.remove());

</script>

<h4 class="faq-title" class:is-open={isOpen} on:click={() => isOpen = !isOpen}>{title}</h4>
{#if isOpen}
  <div class="faq-content" in:slide|local={{duration: 250 }} out:slide|local={{duration: 250 }}>
    {@html sanitizedContent}
  </div>
{/if}
