<script>

  import { breakpoint } from '../utilities/config';
  import { mainNavItems } from '../utilities/store';
  import NavToggle from "./NavToggle.svelte";
  import DesktopNavigation from "./DesktopNavigation.svelte";

  export let logoSrc, linkHref;

  let innerWidth = window.innerWidth;

</script>

<svelte:window bind:innerWidth />

<div class="topbar"></div>

<div class="navbar__inner">
  <div class="logo">
    <a href="{linkHref}"><img src="{logoSrc}" alt="Logo Dance-Festival" /></a>
  </div>
  {#if innerWidth >= breakpoint.lg}
    <DesktopNavigation mainNav={$mainNavItems} />
  {:else}
    <NavToggle />
  {/if}
</div>
