<script>

    export let target;

    let innerWidth = window.innerWidth;
    let colWidths = [];

    //const firstCol = target.children[0];
    //const secondCol = target.children[1];

    // get values of flex-basis styles, transform to array with fr-values, and remove style attribute
    Array.from(target.children).forEach((item, i) => {
       const flexprop = window.getComputedStyle(item).getPropertyValue('flex-basis');
       colWidths[i] = (flexprop !== "auto") ? window.getComputedStyle(item).getPropertyValue('flex-basis').replace(/%/gi, "fr") : '1fr';
       item.removeAttribute('style');
    });

    // create style attribute for grid-template columns depending on viewport width
    $: {
      if (innerWidth >= 768) {
        target.setAttribute('style', 'grid-template-columns:' + colWidths.join(' '));
      }
      else {
        target.removeAttribute('style');
      }
    }

</script>

<svelte:window bind:innerWidth />
