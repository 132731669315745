<script>
	import SiteHeader from './components/Siteheader.svelte';
	import Hero from "./components/hero.svelte";
	import WPBlockGroup from './components/WPBlockGroup.svelte';
	import WPBlockColumns from './components/WPBlockColumns.svelte';
	import MobileNavigation   from './components/MobileNavigation.svelte';
	import CoachCard from './components/CoachCard.svelte';
	import SponsoringCard from './components/SponsoringCard.svelte';
	import TimeTable from "./components/TimeTable.svelte";
	import FaqItem from "./components/FaqItem.svelte";

	// Navbar Component
	if (document.querySelector('#siteHeader')) {
		let siteHeader = document.getElementById('siteHeader');
		new SiteHeader({
			target: siteHeader,
			props: {
				target: siteHeader
			}
		});
	}

	// Hero Component
	if (document.querySelector('.hero')) {
		let hero = document.querySelector('.hero');
		new Hero({
			target: hero,
			props: {
				target: hero
			}
		});
	}

	// Timetable Table Component
	if (document.querySelectorAll('.timetable').length !== 0) {
		Array.from(document.querySelectorAll('.timetable')).forEach((node) => new TimeTable({
			target: node,
			props: {
				target: node
			}
		}));
	}

	// WP Block Column - Coach Card Component
	if (document.querySelectorAll('.coaches .wp-block-column').length !== 0) {
		Array.from(document.querySelectorAll('.coaches .wp-block-column')).forEach((node) => new CoachCard({
			target: node,
			props: {
				target: node
			}
		}));
	}

	// WP Block Column - Sponsoring Card Component
	if (document.querySelectorAll('.sponsoren .wp-block-column').length !== 0) {
		Array.from(document.querySelectorAll('.sponsoren .wp-block-column')).forEach((node) => new SponsoringCard({
			target: node,
			props: {
				target: node
			}
		}));
	}

	// WP Block Group Component
	if (document.querySelectorAll('.wp-block-group').length !== 0) {
		Array.from(document.querySelectorAll('.wp-block-group')).forEach((node) => new WPBlockGroup({
			target: node,
			props: {
				target: node
			}
		}));
	}

	// WP Block Columns Component
	if (document.querySelectorAll('.wp-block-columns').length !== 0) {
		Array.from(document.querySelectorAll('.wp-block-columns')).forEach((node) => new WPBlockColumns({
			target: node,
			props: {
				target: node
			}
		}));
	}

	// FAQ Component
	if (document.querySelectorAll('.faq-item').length !== 0) {
		Array.from(document.querySelectorAll('.faq-item')).forEach((node) => new FaqItem({
			target: node,
			props: {
				target: node
			}
		}));
	}

</script>
<MobileNavigation />
